import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import useStyles from '../style'
import { useTranslation } from 'react-i18next'
import { WEB_VERSION } from '../../../constants/global_constants'
import { alertActions } from '../../../redux/actions/alert_actions'
import { userActions } from '../../../redux/actions/user_actions'
import { CircularProgress } from '@material-ui/core'
import CodeDigits from './codeDigits'
import { useSnackbar } from 'notistack'

const AppUrl = process.env.PUBLIC_URL

const MultifactorCode = ({
  totp,
  alertReducer,
  clear,
  authenticationReducer,
  history,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [code, setCode] = useState('')
  const { email, password } = history.location.state
  const { enqueueSnackbar } = useSnackbar()
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    clear()
  }

  const handleSubmit = (code) =>
    totp(email, password, code).catch((error) => {
      if (error) {
        enqueueSnackbar(t('login.index.otpInvalid'), {
          variant: 'error',
          autoHideDuration: 3000,
          onClose: handleCloseAlert,
        })
      }
    })

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        component={Paper}
        elevation={6}
        square
        className={classes.gridContainerStyle}
      >
        <div className={classes.paper}>
          <img
            alt={t('login.index.altImgLogo')}
            src={AppUrl + '/content/img/logo.png'}
            className={classes.logo}
          />
          <p className={classes.titleLogin}>
            {t('login.index.authenticationCode')}
          </p>
          <form className={classes.form}>
            <CodeDigits
              code={code}
              setCode={setCode}
              callback={handleSubmit}
              isLoading={authenticationReducer.loginPending}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={!authenticationReducer.loginPending}
            >
              {authenticationReducer.loginPending ? (
                <CircularProgress size={24} color="#ffffff" />
              ) : (
                t('login.index.login')
              )}
            </Button>
            <Box mt={5}>
              <Typography variant="body2" color="textSecondary" align="center">
                {'Copyright © '}
                <Link color="inherit" href="https://www.silence.eco">
                  {t('login.index.CopyrightText')}
                </Link>{' '}
                {new Date().getFullYear()}
                {'. - v' + WEB_VERSION}
              </Typography>
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  )
}

function mapState(state) {
  const { alertReducer, authenticationReducer } = state
  return { alertReducer, authenticationReducer }
}

const actionCreators = {
  totp: userActions.totp,
  clear: alertActions.clear,
}

export default connect(mapState, actionCreators)(MultifactorCode)
