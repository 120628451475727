import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    overflow: 'hidden',
  },
  gridContainerStyle: {
    display: 'flex',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // maxWidth: "50%",
    padding: '2.5rem',
    border: '1px solid #dadce0',
    borderRadius: '8px',
    // marginTop: "2%",
    maxWidth: '500px',
  },
  titleLogin: {
    // fontSize: "2em",
    color: '#202124',
    fontSize: '16px',
    fontWeight: '400',
    letterSpacing: '.1px',
    lineHeight: '1.5',
  },
  logo: {
    width: '30%',
    // margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    // marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  imgBackground: {
    background: 'rgb(251,251,251)',
    background:
      'radial-gradient(circle, rgba(251,251,251,1) 0%, rgba(0,0,0,1) 74%)',
  },
  img: {
    width: '100%',
  },
  digitsWrapper: {
    width: '100%',
    display: 'flex',
    gap: '0.75rem',
    position: 'relative',
    justifyContent: 'center',
  },
  digit: {
    display: 'flex',
    width: '2.5rem',
    height: '3rem',
    textAlign: 'center',
    fontSize: '1.5rem',
    border: '1px solid #e0e0e0',
    borderRadius: '6px',
    color: '#3c3c3c',
    fontFamily: 'inherit',
    transition: 'border .05s',
    '&:focus': {
      border: '2px solid #e41622',
      outline: 'none',
    },
  }
}))

export default useStyles
