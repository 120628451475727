import React, { useRef, useEffect } from 'react'
import useStyles from '../style'

export default function CodeDigits({ callback, isLoading, code, setCode }) {
  const classes = useStyles()

  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ]

  const resetCode = () => {
    inputRefs.forEach((ref) => {
      ref.current.value = ''
    })
    inputRefs[0].current.focus()
    setCode('')
  }

  const handleInput = (e, index) => {
    const input = e.target
    const previous = inputRefs[index - 1]
    const next = inputRefs[index + 1]
    const newCode = [...code]

    newCode[index] = input.value
    setCode(newCode.join(''))
    input.select()

    if (input.value === '') {
      if (previous) {
        previous.current.focus()
      }
    } else if (next) {
      next.current.select()
    }
  }

  const handleKeyDown = (e, index) => {
    const previous = inputRefs[index - 1]

    if ((e.keyCode === 8 || e.keyCode === 46) && e.target.value === '') {
      e.preventDefault()
      setCode((prev) => prev.slice(0, index) + prev.slice(index + 1))
      if (previous) {
        previous.current.focus()
      }
    }
  }

  const handlePaste = (e) => {
    const pastedCode = e.clipboardData.getData('text')
    if (pastedCode.length === 6) {
      setCode(pastedCode)
      inputRefs.forEach((inputRef, index) => {
        inputRef.current.value = pastedCode.charAt(index)
      })
    }
  }

  useEffect(() => {
    if (code.length === 6) {
      if (typeof callback === 'function') {
        callback(code).finally(() => {
          resetCode()
        })
      }
    }
  }, [code])

  return (
    <div className={classes.digitsWrapper}>
      {[0, 1, 2, 3, 4, 5].map((index) => (
        <input
          className={classes.digit}
          key={index}
          type="text"
          maxLength={1}
          onChange={(e) => handleInput(e, index)}
          ref={inputRefs[index]}
          autoFocus={index === 0}
          onFocus={(e) => e.target.select()}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste}
          disabled={isLoading}
        />
      ))}
    </div>
  )
}
